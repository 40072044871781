.about__area {
  margin-bottom: 60px;
  background-color: $c-white;
  border-radius: 24px;
  padding: 24px;

  @media (max-width: 991px) {
    padding:  16px;
  }


  .card--s1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $c-background;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid transparent;
    transition: border ease-in .3s;

    svg {
      height: 60px;
      width: auto;
    }

    h3 {
      margin: 16px 0 8px 0;
      font-size: clamp(16px, 3vw, 24px);
      font-weight: 800;
    }

    p {
      color: $c-text;
      font-size: 16px;
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid $c-main;
      transition: border ease-in .3s;
    }

  }


  .card__wrap {
    border-radius: 16px;
    padding: 16px;
    text-align: left;
    width: 25%;
    min-height: 260px;
    transition: transform ease-in .3s;

    @media (max-width: 991px) {
      width: 48%;
    }

    @media (max-width: 600px) {
      width: 47%;
      min-height: 300px;
    }
    @media (max-width: 480px) {
      width: 100%;
      min-height: 100%;
    }


    img {
      height: 64px;
      width: 64px;
      margin-bottom: 16px;
      transition: transform ease-in .3s;

    }

    h4 {
      margin-bottom: 8px;
      font-size: clamp(16px, 3vw, 20px);
      font-weight: 600;
      color: $c-white;
    }

    p {
      font-size: clamp(14px, 3vw, 16px);
      margin-bottom: 0px;
      color: $c-white;
      line-height: 24px;
    }

    &:hover {
      transform: scaleY(1.06);
      transition: transform ease-in .3s;

      img {
        transform: scale(1.06);
        transition: transform ease-in .3s;
      }
    }

  }

  .one {background-color: #F06193;}
  .two {background-color: #EFEFFF;}
  .three {background-color: #FA6440;}
  .four {background-color: #FFF1CB;}

  .--mtop {
    // margin-top: 90px;

    h4, p {
      color: $c-second;
    }

    // @media (max-width: 991px) {
    //   margin-top: 0px;
    // }
  }
  
}


.teams__area {

  margin: 0 0 40px 0;
  .cover {
    margin-bottom: 24px;
    img {
      width: 100%;
      border-radius: 16px;
      object-fit: contain;
    }
  }


  .item {
    background-color: $c-white;
    padding: 16px;
    border-radius: 16px;

    img {
      border-radius: 8px;
      height: 260px;
      width: 100%;
      object-fit: cover;
    }
    .content {
      padding-top: 24px;
      text-align: center;
      h3,h5 {
        margin-bottom: 0;
      }

      h3 {
        font-size: clamp(16px, 3vw, 24px);
        color: $c-main;
        font-weight: 32px;
        margin-bottom: 16px;
      }
      h5 {
        font-size: clamp(14px, 3vw, 16px);
        color: $c-text;
        font-weight: 24px;

        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    } 
  }
}


.image--box {
  // padding: 8px;
  position: relative;
  z-index: 0;

  img {
    height: 100%;
    width: 96%;
    border-radius: 16px;
    margin-right: 16px;
    margin-top: 16px;
  }

  &::before {
    content: "";
    background-image: url("/assets/images/bg--01.jpg");
    background-size: cover;
    width: 96%;
    height: 96%;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: -1;
  }
}


.image--style--1 {
	width: 100%;
	margin: 0 auto;
	line-height: 0;
	position: relative;
    overflow: hidden;
    height: auto;

    &:hover img {
        transform: scale(1.2);
    }
    img {
        width: 100%;
        object-fit: cover;
        transition: all .8s;
    }
    
    article {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background: white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        mix-blend-mode: lighten;
    }
    div {
        background: black;
        // height: 25.3vw;
        height: 100%;
    }
    div:nth-of-type(2) {
        grid-column: 2 / 4;
    }
}