.discover__area {
    padding: 60px 32px;
    border-radius: 24px;
    margin-top: 60px;
    margin-bottom: 60px;
    background-size: cover;
    background-image: linear-gradient(
            to top,
            rgba($c-main, 0.7),
            rgba($c-second, 0.7)
        ),
        url("/assets/images/call-to-action.jpg");
    @media (max-width: 991px) {
        margin-bottom: 32px;
        padding: 40px 16px;
    }

    h2 {
        font-size: clamp(32px, 3vw, 48px);
        font-weight: 700;
        color: $c-white;
        margin-bottom: 0;
        text-align: left;
        padding-right: 40px;
        @media (max-width: 991px) {
            padding-right: 0;
            text-align: center;
        }
    }
}

.form__area {
  text-align: center;
    .feild--item {
        margin-bottom: 16px;
        input {
            height: 45px;
            border-radius: 8px;
            padding: 8px 16px;
            color: $c-white;
            font-weight: 700;
            width: 100%;
            border: 1px solid $c-white;
            background-color: rgba($c-white, 0.4);
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);

            &::placeholder {
                color: rgba($c-white, 1);
            }
        }
        textarea {
            border-radius: 8px;
            padding: 8px 16px;
            color: $c-white;
            font-weight: 600;
            width: 100%;
            border: 1px solid $c-white;
            background-color: rgba($c-white, 0.4);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);

            &::placeholder {
                color: rgba($c-white, 1);
            }
        }
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
}
