.news__area {
  margin-bottom: 60px;
  @media (max-width:991px) {
    margin-bottom: 32px;
  }

  &--item {
    background-color: $c-white;
    border-radius: 16px;
    padding: 16px;
    width: 100%;
    box-shadow: rgba(216, 219, 222, 0.2) 0px 0px 16px;
    border: 1.5px solid rgba($c-gray, .1);

    
  .image {
    position: relative;
    height: 240px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;

    a {
      
    }
    img {
      width: 100%;
      border-radius: 6px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      transition: transform 1s ease-in-out;
    }
    .date {
      position: absolute;
      bottom: 4%;
      left: 2%;
      background-color: $c-white;
      border-radius: 24px;
      padding: 4px 8px;
      font-size: 12px;
      color: $c-text;
    }

    .category {
      position: absolute;
      top: 4%;
      right: 2%;
      background-color: $c-second;
      border-radius: 24px;
      padding: 4px 12px;
      font-size: 14px;
      color: $c-white;
      font-weight: 400;

      a {
        color: $c-white;
        &:hover {
          color: rgba($c-white, 1);
        }
      }
    }
  }

  .title {
    padding: 16px 0 0;
    text-align: center;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: clamp(16px, 3vw, 18px);
      margin-bottom: 0;
      line-height: 24px;
      font-weight: 600;
      color: $c-dark;
      text-align: center;
    }

    p {
      color: $c-text;
      font-size: clamp(12px, 3vw, 14px);
      margin-top: 8px;
      margin-bottom: 0;

    }
  }

  &:hover {
    border: 1.5px solid rgba($c-main, .2);
    -webkit-transition: border 1s ease, background 1s ease;
    -moz-transition: border 1s ease, background 1s ease;
    -o-transition: border 1s ease, background 1s ease;
    transition: border 1s ease, background 1s ease;
    -ms-transition: border 1s ease, background 1s ease;

    .image {
      img {
        transform: scale(1.12) rotate(1deg);
        -webkit-transition: transform 1s ease;
        -moz-transition: transform 1s ease;
        -o-transition: transform 1s ease;
        transition: transform 1s ease;
        -ms-transition: transform 1s ease;
      }
    }
  }
  

  }
  &--head {
    img {
      height: 400px;
      width: 100%;
      object-fit: cover;
      border-radius: 16px;
      margin-bottom: 16px;
    }

    span {
      color: $c-gray;
    }
    .category {
      a {
        color: $c-main-dark;
        text-decoration: none;
      }
    }
  }

  &--body {
    background-color: rgba($c-white, 1);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 24px;
    border: 1px solid rgba($c-main, .2);

    &--title {
      color: $c-dark;
      font-size: clamp(24px, 3vw, 36px);
      font-weight: 800;
      line-height: clamp(32px, 3vw, 56px);
    }

    .t--paragraph--1 > * {
      line-height: clamp(24px, 3vw, 32px);
      color: $c-dark;
    }

    img {
      border-radius: 16px;
      padding: 8px;
      width: max-content;
      object-fit: contain;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    &--tags {

      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 16px;

      h5 {
        margin-bottom: 0;
        margin-right: 12px;
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: $c-gray !important;
        background-color: rgba($c-gray, .1);
        padding: 6px 10px;
        border-radius: 6px;
        margin-right: 8px;

        &:hover {
          color: $c-white !important;
          background-color: rgba($c-gray, 1);
        }
      }
    }
  }

  &--nextPrev {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: rgba($c-white, 1);
    // border-radius: 16px;
    // padding: 16px;

    .item {
      background-color: rgba($c-white, 1);
      border: 1px solid rgba($c-second, .2);
      padding: 12px 16px;
      border-radius: 6px;
      width: 42%;
      text-align: center;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 800px) {
        width: 49%;
        height: auto;
      }

      .a {
        text-decoration: none;
        color: $c-text !important;
      }

      p {
        margin-bottom: 0;
      }

      
    }
    .txt--right {
      text-align: right;
    }
  
  }
}

.formations__area {
  margin-bottom: 60px;
  @media (max-width:991px) {
    margin-bottom: 32px;
  }

  &--item {
    background-color: $c-white;
    border-radius: 16px;
    padding: 16px;
    width: 100%;
    box-shadow: rgba(216, 219, 222, 0.2) 0px 0px 16px;
    border: 1.5px solid rgba($c-gray, .1);

    
  .image {
    position: relative;
    height: 240px;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;

    a {
      
    }
    img {
      width: 100%;
      border-radius: 6px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
      transition: transform 1s ease-in-out;
    }
  }

  .title {
    padding: 16px 0 0;
    text-align: center;

    h3 {
      font-size: clamp(16px, 3vw, 18px);
      margin-bottom: 0;
      line-height: 24px;
      font-weight: 600;
      color: $c-dark;
      text-align: center;
    }

    p {
      color: $c-text;
      font-size: clamp(12px, 3vw, 14px);
      margin-top: 16px;

    }
  }

  &:hover {
    border: 1.5px solid rgba($c-main, .2);
    -webkit-transition: border 1s ease, background 1s ease;
    -moz-transition: border 1s ease, background 1s ease;
    -o-transition: border 1s ease, background 1s ease;
    transition: border 1s ease, background 1s ease;
    -ms-transition: border 1s ease, background 1s ease;

    .image {
      img {
        transform: scale(1.12) rotate(1deg);
        -webkit-transition: transform 1s ease;
        -moz-transition: transform 1s ease;
        -o-transition: transform 1s ease;
        transition: transform 1s ease;
        -ms-transition: transform 1s ease;
      }
    }
  }
  

  }

  
  &--head {
    img {
      height: 600px;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
      margin-bottom: 16px;
    }

    span {
      color: $c-gray;
    }
    .category {
      a {
        color: $c-main-dark;
        text-decoration: none;
      }
    }
  }

  &--body {
    background-color: rgba($c-white, 1);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 24px;
    &--title {
      color: $c-dark;
      font-size: clamp(24px, 3vw, 36px);
      font-weight: 800;
      line-height: clamp(32px, 3vw, 56px);
    }

    .t--paragraph--1 > * {
      line-height: clamp(24px, 3vw, 32px);
    }

    img {
      border-radius: 16px;
      padding: 8px;
      width: max-content;
      object-fit: contain;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    &--tags {

      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 16px;

      h5 {
        margin-bottom: 0;
        margin-right: 12px;
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: $c-gray !important;
        background-color: rgba($c-gray, .1);
        padding: 6px 10px;
        border-radius: 6px;
        margin-right: 8px;

        &:hover {
          color: $c-white !important;
          background-color: rgba($c-gray, 1);
        }
      }
    }
  }

  &--nextPrev {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      background-color: rgba($c-white, 1);
      border: 1px solid rgba($c-second, .2);
      padding: 12px 16px;
      border-radius: 6px;
      width: 42%;
      text-align: center;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 800px) {
        width: 49%;
        height: auto;
      }

      .a {
        text-decoration: none;
        color: $c-text !important;
      }

      p {
        margin-bottom: 0;
      }

      
    }
    .txt--right {
      text-align: right;
    }
  
  }
}

.annonces__single {
  margin-bottom: 60px;
  @media (max-width:991px) {
    margin-bottom: 32px;
  }

  
  &--head {

    .media {
      margin-bottom: 16px;
      img {
        min-height: 100vh;
        border: 1px solid rgba($c-gray, .5);
        height: 100%;
        width: 100%;
        object-fit: fill;
        border-radius: 8px;

        @media (max-width: 767px) {
          object-fit: unset;
          min-height: 60vh;
        }
      }
    }

    span {
      color: $c-gray;
    }
    .category {
      a {
        color: $c-main-dark;
        text-decoration: none;
      }
    }
  }

  &--body {
    background-color: rgba($c-white, 1);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 24px;
    &--title {
      color: $c-dark;
      font-size: clamp(24px, 3vw, 36px);
      font-weight: 800;
      line-height: clamp(32px, 3vw, 56px);
    }

    .t--paragraph--1 > * {
      line-height: clamp(24px, 3vw, 32px);
    }

    img {
      border-radius: 16px;
      padding: 8px;
      width: max-content;
      object-fit: contain;

      @media (max-width: 991px) {
        width: 100%;
      }
    }

    &--tags {

      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 16px;

      h5 {
        margin-bottom: 0;
        margin-right: 12px;
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: $c-gray !important;
        background-color: rgba($c-gray, .1);
        padding: 6px 10px;
        border-radius: 6px;
        margin-right: 8px;

        &:hover {
          color: $c-white !important;
          background-color: rgba($c-gray, 1);
        }
      }
    }
  }

  &--nextPrev {
    display: flex;
    align-items: center;
    justify-content: space-between;


    .item {
      background-color: rgba($c-background, 1);
      padding: 12px 16px;
      border-radius: 6px;
      width: 42%;
      text-align: center;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 800px) {
        width: 49%;
        height: auto;
      }

      .a {
        text-decoration: none;
        color: $c-gray !important;
      }

      p {
        margin-bottom: 0;
      }

      
    }
    .txt--right {
      text-align: right;
    }
  
  }
}

.blog--grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 16px;

  @media (max-width: 1199px) {
      grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }

}





.categories--area {
  h3 {
    font-size: clamp(16px, 3vw, 24px);
    font-weight: 600;
    color: $c-dark;
  }
  &--item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      margin-bottom: 8px;
      padding: 8px 8px;
      border-radius: 8px;
      background-color: rgba($c-white, 1);
      border: 1px solid rgba($c-main, .08);

      &:hover {
        background-color: rgba($c-second, .04);
      }

      .active {
        border: 1px solid rgba($c-main, 1);
      }

      &--left {
        display: flex;
        align-items: center;
          img {
              width: 32px;
              height: 32px;
              padding: 4px;
              margin-right: 12px;
          }
          span {
              font-size: 14px;
              font-weight: 500;
              color: $c-dark;

              small {
                  color: $c-gray; 
              }
          }

      }

      &--right {
          
          svg {
              color: $c-gray;  
          }
      }
  }

  .active {
    border: 1px solid rgba($c-second, 1);
  }
}




.pagination--custom {
    padding-top: 24px;
    ul {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
    }
}




.card__form {
  h3 {
    margin-bottom: 16px;
    color: $c-main;
    font-weight: 600;
    font-size: clamp(16px, 3vw, 18px);
  }
}