

.heading--primary {
  width: 100%;
  text-align: left;
  position: relative;


  h2 {
    color: $c-text;
    --minFontSize: 24px;
    --maxFontSize: 40px;
    --scaler: 6vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 700;
    text-align: left;
    line-height: clamp(32px,6vw, 50px);
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 24px;
    
  }

  // &::after {
  //   content: url("/assets/images/favicon-c.svg");
  //   // background-image: url("/assets/images/favicon-c.svg");
  //   left: -14px;
  //   top: -58px;
  //   opacity: .5;
  //   position: absolute;
  //   width: 100%;
  //   height: 60px;
  //   background-repeat: no-repeat;
  //   z-index: 0;
  // }

  p {
    color: $c-gray;
    font-size: clamp(16px,3vw, 20px);
    margin-top: 8px;
    font-weight: 500;
    line-height: 28px;
  }

 
}

.heading--primary-w {
  width: 100%;
  text-align: left;
  position: relative;


  h2 {
    color: $c-white;
    --minFontSize: 24px;
    --maxFontSize: 40px;
    --scaler: 6vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 700;
    text-align: left;
    margin-bottom: 8px;
    line-height: clamp(32px,6vw, 50px);
    z-index: 1;

    display: flex;
    align-items: center;
    gap: 24px;

    
  }

  // &::after {
  //   content: url("/assets/images/favicon-white.svg");
  //   // background-image: url("/assets/images/favicon-c.svg");
  //   left: -14px;
  //   top: -58px;
  //   opacity: .5;
  //   position: absolute;
  //   width: 100%;
  //   height: 60px;
  //   background-repeat: no-repeat;
  //   z-index: 0;
  // }
 

  p {
    color: $c-gray;
    font-size: clamp(16px,3vw, 20px);
    margin-top: 16px;
    font-weight: 500;
    line-height: 28px;
  }

 
}

.heading--second {
  width: 100%;
  text-align: left;
  h3 {
    color: $c-second;
    --minFontSize: 28px;
    --maxFontSize: 48px;
    --scaler: 6vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 800;
    text-align: left;
    margin-bottom: 16px;
    line-height: clamp(40px,6vw, 64px);
    z-index: 1;

    span {
      color: $c-second;
      font-weight: 600;
    }
  }

  svg {
    margin-bottom: 8px;
  }
 
}


.subheading {
  display: flex;
  align-items: center;

  h3 {
    margin-bottom: 0;
    font-size: clamp(16px, 3vw, 24px);
    font-weight: 600;
    color: $c-main-dark;
  }

  img {
    height: 24px;
    margin-right: 8px;
  }
}

.subheading--2 {
  margin-bottom: 0;
  font-size: clamp(16px, 3vw, 24px);
  font-weight: 600;
  line-height: 38px;
  color: $c-second;
}



.font--mmtsdr {font-family: Mmtsdr !important}
.font--hello {font-family: HelloParisRegular !important}


.t--paragraph--1 {
 --minFontSize: 14px;
 --maxFontSize: 20px;
 --scaler: 4vw;
 font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
 font-weight: 400;
 line-height: 34px;
 color: $c-text;
 padding-bottom: 8px;
 text-align: justify;


}



.t--paragraph--white {
  --minFontSize: 14px;
  --maxFontSize: 20px;
  --scaler: 4vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 300;
  line-height: 38px;
  color: $c-white;
  padding-bottom: 0;
  text-align: justify;
 
 
   @media (max-width: 1366px){
     --minFontSize: 13px;
     --maxFontSize: 16px;
     --scaler: 5vw;
     font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
     line-height: 24px;
   }
 }

 .t--paragraph--w-small {
  --minFontSize: 14px;
  --maxFontSize: 16px;
  --scaler: 3vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 300;
  line-height: 28px;
  color: $c-white;
  padding-bottom: 0;
  text-align: justify;
 }
 


.t--paragraph--black {
 --minFontSize: 14px;
 --maxFontSize: 18px;
 --scaler: 5vw;
 font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
 font-weight: 400;
 line-height: 32px;
 color: $c-black;
 padding-bottom: 8px;
 text-align: justify;
 &:last-child {
   padding-bottom: 0;
   margin-bottom: 0;
 }

 @media (max-width: 1366px){
  --minFontSize: 13px;
  --maxFontSize: 16px;
  --scaler: 5vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  line-height: 24px;


}

 @media (max-width: 800px) {
   line-height: 24px;
 }

}

.t--paragraph--small {
 --minFontSize: 13px;
 --maxFontSize: 16px;
 --scaler: 7vw;
 font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
 font-weight: 400;
 line-height: 28px;
 color: $c-text;
 padding-bottom: 8px;
 text-align: justify;


 &:last-child {
   padding-bottom: 0;
   margin-bottom: 0;
 }
}


.main::first-letter {
  color: $c-second;
  font-size: 24px;
  padding-left: 4px;
}
.second::first-letter {
  color: $c-text;
  font-size: 24px;
  padding-left: 4px;
}
.third::first-letter {
  color: $c-main;
  font-size: 24px;
  padding-left: 4px;
}

.fifth::first-letter {
  color: $c-text;
  font-size: 24px;
  padding-left: 4px;
}

.image--col {
  img {
    width: 100%;
    padding: 24px;
  }
}

.s--image {
 width: 100%;
 overflow: hidden;
 position: relative;
 padding: 12px;
 height: auto;
 
   &:hover img  {
     transform: scale(1.5);
   }

   img {
     width: 100%;
     border-radius: 10px;
     object-fit: cover;
     transition: all .8s;
   }
}

.s2--image {
  width: 80%;
  padding: 12px;
  height: auto;
    
 }

.s--image-2 {
 width: 100%;
 overflow: hidden;
 position: relative;
 padding: 12px;
 
   &:hover img  {
     transform: scale(1.5);
   }

   img {
     width: 100%;
     border-radius: 10px;
     object-fit: cover;
     transition: all .8s;
   }

   @media (max-width: 800px) {
     width: 200px;
     margin: 0 auto;
   }
}

.s--image--90 {
 width: 90%;
 padding: 12px;
}

.image--random {
 position: relative;
 &--01 {
   transition: all .5s;
   transition-property: transform;
   &:hover {
     transform: scale(1.1) rotate(1.5deg) translateY(-12px) translateX(-2px);
   }
   padding-left: 80px;
 }
 &--02 {
   position: absolute;
   bottom: -114px;
   left: 7%;
   width: 70%;
   border-radius: 12px;
   transform-origin: left bottom;
   transition: all .5s;
   transition-property: transform;

   &:hover {
     transform: scale(1.1) rotate(1.5deg) translateX(12px) translateY(-12px);
   }

   @media (max-width: 800px) {
     bottom: -90px;
     left: 4%;
     width: 70%;
   }
 }
 &--03 {
   position: absolute;
   right: -40px;
   width: 30%;
   border-radius: 12px;
   transition: all .5s;
   transition-property: transform;

   &:hover {
     transform: scale(1.15) rotate(-2deg);
   }
   @media (max-width: 800px) {
     right:0;
     width: 35%;
   }
 }

}

.pega {
 font-weight: 600;
 color: $c-second;
 span {
   color: $c-main;
 }
}

.m--bold--primary {
 font-weight: 600;
 color: $c-main;
}

.text--color--third {
 color: $c-text;
}










.animate--float  {
 overflow: hidden;
 transform: translatey(0px);
 animation: float 6s ease-in-out infinite;
}


.animate--float--1  {
 overflow: hidden;
 transform: translatey(0px);
 animation: float1 6s ease-in-out infinite;
}

.animate--float--2  {
  overflow: hidden;
  transform: translateX(0px);
  animation: float1 6s ease-in-out infinite;
 }



@keyframes float {
 0% {
   transform: translatey(0px);
 }
 50% {
   transform: translatey(-10px);
 }
 100% {
   transform: translatey(0px);
 }
}


@keyframes float1 {
 0% {
   transform: translatey(0px);
 }
 50% {
   transform: translatey(10px);
 }
 100% {
   transform: translatey(0px);
 }
}

@keyframes float2 {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0px);
  }
 }




@keyframes animateLine {
 0% {
 transform:scaleX(0);
 transform-origin: left;
 }
 50%
 {
   transform:scaleX(1);
 transform-origin: left;
 }
 50.1%
 {
   transform:scaleX(1);
 transform-origin: right;
   
 }
 
 100%
 {
   transform:scaleX(0);
 transform-origin: right;
   
 }
 
 
} 



.thankyou--area {

  img {
    height: 160px;
    margin-bottom: 16px;
  }
  h2 {
    color: $c-second;
    font-weight: 700;
  }
}


// Alert

.custom--alert--success {
  background-color: rgba(0, 225, 131, 0.2);
  border: 1px solid #00e082;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 0;
  color: #00844d;
  font-weight: 600;

  svg {
    margin-right: 16px;
  }
}

.custom--alert--danger {
  background-color: rgba(234, 70, 70,.2);
  border: 1px solid #EA4646;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 0;
  color: #8f1d1d;

  svg {
    margin-right: 16px;
  }

  ul {
    margin-left: 24px;
    li {
      color: #8f1d1d;
    }
  }
}


.text-danger {
  padding: 8px;
  background-color: rgba(234, 70, 70,.1);
  border: 1px solid rgba(234, 70, 70,.4);
  color: #8f1d1d;
  margin-top: 16px;
  border-radius: 8px;
  text-align: center;
}

.text-success {
  padding: 16px;
  background-color: rgba(0, 177, 102, .1);
  border: 1px solid rgba(0, 177, 102, .4);
  color: #00814b;
  margin-top: 16px;
  border-radius: 8px;
  text-align: center;
}

.text-danger-dark {
  padding: 8px;
  background-color: rgba(234, 70, 70,.8);
  border: 1px solid rgba(234, 70, 70,1);
  color: #fff;
  margin-top: 16px;
  border-radius: 8px;
  text-align: center;
}

.text-success-dark {
  padding: 16px;
  background-color: rgba(0, 177, 102, .8);
  border: 1px solid rgba(0, 177, 102, 1);
  color: #fff;
  margin-top: 16px;
  border-radius: 8px;
  text-align: center;
}
