
.button--simple {
  display: inline-flex;
  height: 50px;
  padding: 0 30px;
  text-transform: none;
  text-decoration: none;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  @media (max-width: 800px) {
    padding: 0 20px;
    height: 44px;
  }
}

a {
  text-decoration: none;
  letter-spacing: 1px;
}





/* Second Button */

.button-1 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background-color: $c-second;
  cursor: pointer;
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-white !important;
    padding: 12px 24px;
    min-width: 220px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;

    @media (max-width:991px) {
      min-width: 160px;
    }
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-main;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white;
  }
}

.button-small {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background-color: $c-second;
  cursor: pointer;
  @media (max-width: 991px) {
    height: 36px;
  }
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-white !important;
    padding: 12px 24px;
    min-width: 140px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
    @media (max-width: 991px) {
      min-width: 120px;
      padding: 8px 10px;
    }
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-main;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white;
  }
}

.button-2 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background-color: rgba($c-second, .1);
  border: 1px solid $c-second;
  cursor: pointer;
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second !important;
    padding: 12px 24px;
    min-width: 180px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
  }

  button {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second !important;
    padding: 12px 24px;
    min-width: 180px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }

  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: rgba($c-second,.1);
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover {
    // background-color: rgba($c-second,.1);
  }

  &:hover a {
    color: $c-second !important;
  }
}

.button-border {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid $c-white;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-white !important;
    padding: 8px 24px;
    min-width: 220px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
    @media (max-width:991px) {
      min-width: 160px;
    }
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-second;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover{
    border: 1px solid $c-second;
  }

  &:hover a {
    color: $c-white;
  }
}


.button-large {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 60px;
  background-color: rgba($c-second, 1);
  cursor: pointer;
  padding: 0 !important;


  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-white;
    font-size: 16px;
    text-transform: none;
    font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
  }
  button {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-white;
    border: none;
    font-size: 16px;
    padding: 0 24px;
    min-width: 300px;
    text-transform: none;
    font-weight: 600;
    background: transparent;
    width: 100%;
    height: 100%;
    @media (max-width: 800px) {
      min-width: 100% !important;
      width: 100% !important;
    }

   
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-main;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a,
    &:hover button {
    color: $c-white;
  }
}


.button-link {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background: rgba($c-second, .1);
  cursor: pointer;
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-main !important;
    padding: 12px 24px;
    min-width: 220px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: rgba($c-main, .1);
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
  }
}

.button-3 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background-color:  rgba($c-main, .1);
  cursor: pointer;
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-main !important;
    padding: 12px 16px;
    min-width: 140px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
    span {
      padding-left: 4px;
    }

    @media (max-width: 460px) {
      padding: 8px;
      font-size: 12px;
    }
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: rgba($c-main, .2);
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
  }
}

.button-danger {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background-color:  rgba(180, 22, 22, 0.1);
  cursor: pointer;
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: rgba(180, 22, 22, 1) !important;
    padding: 12px 8px;
    min-width: 40px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;

    i {
      margin: 0 auto;
    }
  }

 
}


.button-4 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  height: 44px;
  background-color: rgba($c-second, .1);
  border: 1px solid $c-second;
  cursor: pointer;
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second !important;
    padding: 12px 24px;
    min-width: 180px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
  }

  button {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second !important;
    padding: 12px 24px;
    min-width: 180px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: transparent;
    }

  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: rgba($c-second,.1);
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover {
    // background-color: rgba($c-second,.1);
  }

  &:hover a {
    color: $c-second !important;
  }
}



// --------------------------  
// 
.fronty--btn {
  height: 50px;
  min-width: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 8px;
  padding: 8px 24px;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  // box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
  //   7px 7px 20px 0px rgba(199, 199, 199, 0.1),
  //   4px 4px 5px 0px rgba(199, 199, 199,.1);
  outline: none;
  border: none;
  font-size: clamp(14px, 3vw, 16px);
  
  @media (max-width: 676px) {
    padding: 16px 16px;
    height: auto;
  }

  span {
    margin: 0 8px;
  }
}

.primary {
  background-color: $c-main !important;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;
  text-decoration: none;
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-second;
    border-radius: 8px;
    //  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    //  7px 7px 20px 0px rgba(0,0,0,.1),
    //  4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff !important;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }

  
}

.borderd--btn {
  background-color: transparent !important;
  border: 2px solid $c-white;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-white;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  &:hover {
    color: $c-main !important;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }

  
}

.second {
  background: $c-second;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;

  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-danger;
    border-radius: 8px;
    //  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    //  7px 7px 20px 0px rgba(0,0,0,.1),
    //  4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}

.dark {
  background: $c-dark;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;

  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-dark;
    border-radius: 8px;
    //  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    //  7px 7px 20px 0px rgba(0,0,0,.1),
    //  4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}

.dark-nohover {
  background: $c-dark;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;

  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-second;
    border-radius: 8px;
    //  box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
    //  7px 7px 20px 0px rgba(0,0,0,.1),
    //  4px 4px 5px 0px rgba(0,0,0,.1);
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
 
}

.third {
  background: rgba( $c-main, .08) !important;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;
  color: $c-main !important;
  
  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-main;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  &:hover {
    color: $c-white !important;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}

.forth {
  background: rgba( $c-second, .1) !important;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;
  color: $c-second;
  
  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-second;
      border-radius: 8px;
    transition: all 0.3s ease;
  }
  &:hover {
    color: $c-white !important;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}

.danger-btn {
  background: rgba( $c-danger, .1) !important;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 50px;
  width: max-content;
  justify-content: center;
  color: $c-danger;
  
  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-danger;
      border-radius: 8px;
    transition: all 0.3s ease;
  }
  &:hover {
    color: $c-white !important;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}

.fifth {
  background: rgba( $c-gray, .08) !important;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 8px;
  width: max-content;
  justify-content: center;
  color: $c-gray;
  
  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-gray;
      border-radius: 8px;
    transition: all 0.3s ease;
  }
  &:hover {
    color: $c-white !important;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}

.whatsapp {
  background: rgba( $c-success, 1) !important;
  border: none;
  z-index: 1;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  padding: 8px;
  width: max-content;
  justify-content: center;
  color: $c-white;
  
  @media (max-width: 676px) {
    min-width: auto;
    height: auto;
  }
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: $c-success;
      border-radius: 8px;
    transition: all 0.3s ease;
  }
  &:hover {
    color: $c-white !important;
  }
  &:hover:after {
    left: 0;
    width: 100%;
  }
  &:active {
    top: 2px;
  }
}


.btn--height-60 {
 height: 60px;
}
.btn--height-50 {
 height: 50px;
}
.btn--height-40 {
  height: 40px;
  font-size: 14px !important;
 }
 .btn--height-40-40 {
  height: 40px;
  width: 40px;
 }
 .btn--height-50-50 {
  height: 50px;
  width: 50px;
 }

.btn--full {
  width: 100%;
}

.btn--half {
  width: 30%;

  @media (max-width: 991px) {
    width: 50%;
  }
}
.btn--max {
  width: max-content;
}
.btn--min {
  min-width: 180px !important;
}