

.header__area {
    border-radius: 24px;
     -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba($c-white, 0.5);  
    padding: 8px;
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin: 0 auto;
    z-index: 999;

    // &::after {
    //     content: url('/assets/images/shape--0001.svg');
    //     position: absolute;
    //     top: 10px;
    //     left: 18%;
    //     opacity: .2;
    //     right: 0;
    //     margin: 0 auto;
    //     width: 100%;
    // }



    @media (max-width: 512px) {
        padding: 8px; 
        border-radius: 16px;
    }
    &--content {
        background-color: $c-white;
        border-radius: 16px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        @media (max-width: 512px) {
            gap: 8px;
            padding: 8px;   
            border-radius: 8px;
        }

        .logo {
            width: fit-content;
            img {
                height: 80px;
                width: 100%;
                @media (max-width: 512px) {
                    height: 50px;       
                }
            }
        }

        .menu {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
            gap: 16px;

            @media (max-width: 512px) {
                gap: 8px;
            }

            .social {
                display: flex;
                align-items: center;
                gap: 16px;

                

                span {
                    font-style: italic;

                    @media (max-width: 584px) {
                        display: none;
                    }
                }

                .items {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 8px;

                    a {
                        height: 40px;
                        width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: rgba($c-main, .1);
                        border-radius: 8px;

                        @media (max-width: 512px) {
                            border-radius: 4px;
                            height: 32px;
                            width: 32px;

                            i {
                                font-size: 16px;
                            }
                        }

                        &:hover {
                            background-color: rgba($c-main, 1);
                            color: $c-white;
                        }

                    }
                }
            }
            

            .navigation {
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    gap: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    li {
                        font-size: clamp(12px, 3vw, 16px);
                        font-weight: 500;
                        position: relative;
                        text-align: center;
                        transition: all ease-in .4s;
                       
                        // @media (max-width: 1340px) {
                        //     font-size: 15px
                        // }
                        @media (max-width: 1260px) {
                            font-size: 15px
                        }
                        @media (max-width: 1200px) {
                            font-size: 14px
                        }

                        a {
                            color: $c-text;
                            text-decoration: none;
                        }

                      
                        &:after {
                            content: '';
                            display: block;
                            margin: 0 auto;
                            width: 100%;
                            position: absolute;
                            bottom: -8px;
                            height: 100%;
                            left: 0;
                            right: 0;
                            // height: 40px;
                            // background-color: $c-main;
                            -webkit-transition: opacity .3s ease,width .3s ease;
                            -moz-transition: opacity .3s ease,width .3s ease;
                            -o-transition: opacity .3s ease,width .3s ease;
                            transition: opacity .3s ease,width .3s ease;
                            -ms-transition: opacity .3s ease,width .3s ease;
                            // border-radius: 10px;
            
                        }
            
                        
                        &.active,
                        &:hover {
                            color: $c-main;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            a {
                                color: $c-main
                            }
            
                           
                            &:after {
                                content: "";
                                display: block;
                                margin: 0 auto;
                                width: 60%;
                                position: absolute;
                                bottom: -16px;
                                height: 4px;
                                border-radius: 4px;
                                background-color: $c-main;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                            }
                        }
                        
                    }

                   
                }
                @media (max-width: 1199px) {
                    display: none;
                }
            }

            

            .drawer--btn {
                display: none;
                @media (max-width: 1199px) {
                    display: block;
                    background-color: rgba($c-gray, .1);
                    padding: 4px 8px;
                    border-radius: 8px;
                    color: $c-second;
                    font-weight: 600;
                    font-size: clamp(16px, 3vw, 16px);
                }

                
            }

        }
    }
}




.sticky {
  position: sticky;
  top: 0;
  z-index: 9999;
 
  .header__area {
    box-shadow: rgba(204, 182, 206, 0.2) 0px 2px 10px;
  }
}



.swipe-nav {
    position: fixed;
    z-index: 40000;
    top: -2em;
    right: 0;
    bottom: -2em;
    left: 0;
    visibility: hidden;
    transition: visibility 0.2s ease;
  
  &__overlay {
    cursor: default;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 0.2s ease;
    transform: translateZ(0);
    opacity: 0;
    background: rgba(91, 12, 32, 0.6);
    will-change: opacity;
  }
  
  &__panel {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 30%;
    margin: 2em 0;
    transition: 0.2s ease-out;
    transition-property: transform;
    transform: translate3d(-100%, 0, 0);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(10px);
    background-color: rgba($c-white, 0.8); 
    will-change: transform;

    @media (max-width: 991px) {
        width: 40%;
    }
    @media (max-width: 767px) {
        width: 60%;
    }
    @media (max-width: 512px) {
        width: 80%;
    }
    @media (max-width: 380px) {
        width: 92%;
    }
  }
  
  &__scroller {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__inner {
    position: relative;
    min-height: 100%;
    
    .menu-button {
      top: -2.125em;
    }
  }

  &__close {
    cursor: pointer;
  }

  &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba($c-white, 0.8); 
      padding: 16px;
      position: sticky;
      left: 0;
      top: 0;
      width: 100%;
      border-bottom: 1px solid rgba($c-gray, .2);

      .title {
          font-size: clamp(16px, 3vw, 24px);
          font-weight: 600;
          color: rgba($c-second, 1);
      }
  }
  &__content {
      padding: 16px;

      .navigation {
          ul {
              list-style: none;
              padding: 0;
              margin: 0;
              gap: 16px;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              flex-direction: column;

              li {
                  font-size: clamp(16px, 3vw, 18px);
                  font-weight: 600;
                  position: relative;
                  width: 100%;
                  padding-left: 8px;
                  padding-top: 16px;
                  padding-bottom: 16px;
                  transition: all ease-in .4s;

                  a {
                      color: $c-text;
                      text-decoration: none;
                  }

                  &:hover {
                      padding-left: 16px;
                      transition: all ease-in .4s;

                      &::after {
                          content:'';
                          position: absolute;
                          left: 0;
                          top: 0;
                          bottom: 0;
                          background-color: $c-main;
                          width: 4px;
                          margin: 0 auto;
                          height: 100%;
                          border-radius: 50px;
                          transition: all ease-in .4s;

                      }
                      a {
                          color: $c-main;
                      }
                  }
                  
              }

              .active {
                  padding-left: 16px;
                  transition: all ease-in .4s;
                  &::after {
                      transition: all ease-in .4s;
                      content:'';
                      position: absolute;
                      left: 0;
                      top: 0;
                      bottom: 0;
                      background-color: $c-main;
                      width: 4px;
                      margin: 0 auto;
                      height: 100%;
                      border-radius: 50px;
                  }
                  a {
                      color: $c-main;
                  }
              }
          }
         
      }
  }
}
  .js .swipe-nav__panel:after {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    visibility: visible;
    width: 1em;
    content: '';
  }
  
  .swipe-nav--open,
  .no-js .swipe-nav:target {
    visibility: visible;
    
    .swipe-nav__overlay {
      opacity: 1;
    }
    
    .swipe-nav__panel {
      transform: translate3d(0,0,0);
    }
  }




.sidebar__wrapper {
    background: rgba($c-second, .3);
    
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.5s, visibility 0.5s;
    visibility: hidden;
    width: 100%;
    height: 100%;
    z-index: 40000;


    &--right {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        position: absolute;
        right: 0;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;

        position: absolute;
        height: 100%;
        width: 30%;
        max-width: 85vw;
        margin: 0;
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        background-color: rgba($c-white, 0.8); 
        z-index: 99;
        overflow-y: auto;
      
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        transition: background 0.5s, transform 0.5s;
    
        @media (max-width: 991px) {
            width: 40%;
        }
        @media (max-width: 767px) {
            width: 60%;
        }
        @media (max-width: 512px) {
            width: 80%;
        }
        @media (max-width: 380px) {
            width: 92%;
        }
    }
    &--open {
      cursor: pointer;
    }
    
    &--close {
      cursor: pointer;
    }

    &--head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba($c-white, 0.8); 
        padding: 16px;
        position: sticky;
        left: 0;
        top: 0;
        width: 100%;
        border-bottom: 1px solid rgba($c-gray, .2);

        .title {
            font-size: clamp(16px, 3vw, 24px);
            font-weight: 600;
            color: rgba($c-second, 1);
        }
    }
    &--content {
        padding: 16px;

        .navigation {
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                gap: 16px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;

                li {
                    font-size: clamp(16px, 3vw, 18px);
                    font-weight: 600;
                    position: relative;
                    padding-left: 8px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    transition: all ease-in .4s;

                    a {
                        color: $c-text;
                        text-decoration: none;
                    }

                    &:hover {
                        padding-left: 16px;
                        transition: all ease-in .4s;

                        &::after {
                            content:'';
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            background-color: $c-main;
                            width: 4px;
                            margin: 0 auto;
                            height: 100%;
                            border-radius: 50px;
                            transition: all ease-in .4s;

                        }
                        a {
                            color: $c-main;
                        }
                    }
                    
                }

                .active {
                    padding-left: 16px;
                    transition: all ease-in .4s;
                    &::after {
                        transition: all ease-in .4s;
                        content:'';
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        background-color: $c-main;
                        width: 4px;
                        margin: 0 auto;
                        height: 100%;
                        border-radius: 50px;
                    }
                    a {
                        color: $c-main;
                    }
                }
            }
           
        }
    }
  }
  
  .js-menu {
   
  }
  
  .js-menu--left {
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
  }
  
  
  .js-menu__expanded {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }