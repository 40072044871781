:focus {
  outline: none;
}


.subscribe--input {

  padding: 16px 0;

  label {
    color: $c-gray;
  }

  &--container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      color: $c-gray;
      opacity: .5;
      margin: auto 0;
      line-height: 56px;
    }

    .button {
      position: absolute !important;
      padding: 0 16px;
      right: 8px;
      top: 0;
      bottom: 0;
      line-height: 56px;
      margin: auto;
      text-transform: capitalize;
    }

    input {
      height: 56px;
      padding-left: 40px;
      font-weight: 600;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-weight: 400;
      opacity: .7;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-weight: 400;
      opacity: .7;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      font-weight: 400;
      opacity: .7;
    }
  }

}





.form__area {
  .item--group {
    width: 100%;
    margin-bottom: 16px;
    position: relative;

    input,
    select,
    textarea {
      font-size: 16px;
      font-weight: 500;
      color: $c-second;
      // height: 60px;
      border-radius: 4px;

      background-color: $c-background;
      border: 1px solid $c-dark;

      padding: 16px 24px;
      width: 100%;
      padding-left: 48px;

      &::placeholder {
        font-weight: 400;
        color: $c-gray;
      }

    }

    i {
      position: absolute;
      left: 16px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      color: rgba($c-main, .5);
      font-size: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }


    select {

      /* styling */
      display: inline-block;

      /* reset */

      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    select {
      background-image:
        linear-gradient(45deg, transparent 60%, gray 60%),
        linear-gradient(135deg, gray 60%, transparent 60%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 24px) calc(1.4em + 2px),
        calc(100% - 19px) calc(1.4em + 2px),
        calc(100% - 3em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 2.2em;
      background-repeat: no-repeat;
    }

    select:focus {
      background-image:
        linear-gradient(45deg, $c-second 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $c-second 50%),
        linear-gradient(to right, #ccc, #ccc);
      background-position:
        calc(100% - 19px) 1.4em,
        calc(100% - 24px) 1.4em,
        calc(100% - 3em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 2.2em;
      background-repeat: no-repeat;
      border-color: $c-dark;
      outline: 0;
    }



  }
}




.form--wrap {
  text-align: center;
  background-color: $c-white;
  
  padding: 24px;
  border-radius: 16px;

  @media (max-width: 991px) {
      padding: 16px;
  }

  .group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 700px) {
          flex-wrap: wrap;
      }
  }

  .field--input {
      flex-grow: 1;
      margin-right: 16px;
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      text-align: left;

      @media (max-width: 700px) {
          margin-right: 0;
      }

      &:last-child {
          margin-right: 0;
      }

      label {
          color: $c-gray;
          font-weight: 400;
          font-size: 14px;
      }

      select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin-top: 8px;
          height: 50px;
          border-radius: 8px;
          border: 1.2px solid rgba($c-gray, .3);
          padding: 0 16px;
          background-color: rgba($c-gray, .08);
          width: 100%;

         
      }
      input {
          margin-top: 8px;
          height: 50px;
          border-radius: 8px;
          padding: 0 16px;
          border: 1.2px solid rgba($c-gray, .3);
          background-color: rgba($c-gray, .08);

      }

      textarea {
          margin-top: 8px;
          border-radius: 8px;
          padding: 8px 16px;
          border: 1.2px solid rgba($c-gray, .3);
          background-color: rgba($c-gray, .08);
      }

      .checkbox {
          display: flex;
          align-items: center;

          input {
              margin-top: 0;
              margin-right: 8px;
              height: 40px;
          }
      }

  }


  
}
