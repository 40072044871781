.row__area {
  background-color: $c-white;
  border-radius: 16px;
  padding: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  grid-gap: 32px;
  margin-bottom: 60px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    grid-gap: 24px;
  }

  @media (max-width: 991px) {
    padding: 16px;
    grid-gap: 16px;

  }


  .content {

    .head {
      h3 {
        margin-bottom: 16px;
        color: $c-main-dark;
        font-size: clamp(18px, 3vw, 24px);
        font-weight: 600;
        line-height: clamp(24px, 3vw, 32px);
      }
    }

  }

  .media {
    img {
      min-height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}