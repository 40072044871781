.hero__section {
    margin-top: 8px;
    margin-bottom: 60px;
    position: relative;
    border-radius: 24px;

    &--image {
        height: 460px;
        width: 100%;
        object-fit: cover;
        border-radius: 24px;
        @media (max-width: 991px) {
            height: 400px;
            border-radius: 16px;
        }
    }

    &--overlay {
        background-image: linear-gradient(
            to bottom,
            rgba($c-main, 0.4),
            rgba($c-second, 0.4)
        );
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 24px;
        z-index: 1;
        @media (max-width: 991px) {
            border-radius: 16px;
        }
    }

    &--img {
      img {
        height: 40px;
      }
    }

    &--caption {
        position: absolute;
        right: 6%;
        left: 6%;
        bottom: 6%;
        top: 6%;
        margin: auto;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1.25rem;
        text-align: center;
        animation-duration: 1s;
        animation-delay: 2s;

        @media (max-width: 767px) {
            right: 4%;
            left: 4%;
            padding: 0;
        }

        .img {
            height: 28px;
        }

        &--title {
            --minFontSize: 20px;
            --maxFontSize: 42px;
            --scaler: 3vw;
            font-size: clamp(
                var(--minFontSize),
                var(--scaler),
                var(--maxFontSize)
            );
            // font-weight: 700;
            padding: 16px 0 0;
            color: $c-white;
            // text-shadow: 2px 2px $c-white;
            font-weight: 700;
            line-height: clamp(28px, 5vw, 56px);
            margin-bottom: 16px;
        }
        &--subtitle {
            --minFontSize: 16px;
            --maxFontSize: 24px;
            --scaler: 3vw;
            font-size: clamp(
                var(--minFontSize),
                var(--scaler),
                var(--maxFontSize)
            );
            font-weight: 400;
            color: $c-light;
            line-height: 32px;
            margin-bottom: 16px;
        }
        &--actions {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            animation-duration: 1s;
            animation-delay: 2.2s;
            margin: 16px 0 8px;
        }
    }
}
