@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '../components/header';
// @import url('https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css');

:root {

}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: 'Be Vietnam Pro', sans-serif;
  // font-family: 'M PLUS Rounded 1c', sans-serif;
 font-family: 'Barlow', sans-serif;

}

a {
  text-decoration: none;
  color: $c-main;
}

a:hover {
text-decoration: none;
}

::-moz-selection { 
  background-color: rgba($c-second, .7);
  color: white;
}
::selection { 
  background-color: rgba($c-second, .7);
  color: white;; }

figure {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 10px;
	margin-inline-end: 0;
}

body {
  /* overflow: hidden; */
  font-size: 16px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: 'Be Vietnam Pro', sans-serif;
  // font-family: 'M PLUS Rounded 1c', sans-serif;
 font-family: 'Barlow', sans-serif;

  font-weight: 400;
  background-color: $c-background;
  color: $c-text;
  // overflow-x: hidden ;
}




.sticky-top {
  top: 8.5rem;
  z-index: 0;

  @media (max-width: 991px) {
      position: static;
  }
}

@media (max-width: 1399px) {
  .container {
    max-width: 100% ;
    padding: 0 64px;
  }
}


@media (max-width: 1299px) {
  .container {
    max-width: 100% ;
    padding: 0 32px;
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 100% ;
    padding: 0 32px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 100% ;
    padding: 0 32px ;
  }
}

@media (max-width: 576px) {
  .container {
    max-width: 100% ;
    padding: 0 16px ;
  }
}

@media (max-width: 400px) {
  .container {
    max-width: 100% ;
    padding: 0 16px ;
  }
}