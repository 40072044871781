.footer__area {

 
  .content {
    min-height: 440px;

    background-color: $c-white;
    border-radius: 24px;
    // border: 2px solid rgba($c-second, 1);
    padding: 24px;
    position: relative;


    .head {
      h3 {
        color: $c-text;
        font-size: clamp(16px, 3vw, 24px);
        font-weight: 500;
        position: relative;
        
        &:after {
          background: $c-second;
          display: block;
          content: "";
          position: absolute;
          width: 40px;
          margin: 0 auto;
          left: 0;
          border-radius: 10px;
          height: 4px;
          bottom: -12px;
      }
      }
    }

    .info {

      margin-top: 32px;

      .item {
        margin-bottom: 16px;

        &:last-child{ margin-bottom: 0; }
        h5 {
          color: $c-dark2;
          font-size: clamp(14px, 3vw, 16px);
          font-weight: 600;
        }
        h4 {
          color: $c-text;
          margin-bottom: 0;
          font-size: clamp(14px, 3vw, 16px);
          font-weight: 500;
          line-height: 24px;

          a {
            color: $c-text;
          }
        }
      }
    }


    .social {
      display: flex;
      align-items: center;
      gap: 16px;

      

      span {
          font-style: italic;

          @media (max-width: 512px) {
              display: none;
          }
      }

      .items {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;

          a {
              height: 40px;
              width: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba($c-main, .1);
              border-radius: 8px;

              @media (max-width: 512px) {
                  border-radius: 4px;
                  height: 32px;
                  width: 32px;

                  i {
                      font-size: 16px;
                  }
              }

              &:hover {
                  background-color: rgba($c-main, 1);
                  color: $c-white;
              }

          }
      }
  }


    iframe {
      border-radius: 16px;
      margin-top: 32px;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 350px;
      flex-direction: column;

      img {
        margin-bottom: 24px;
        width: 80%;
        
      }
    }

    .logo--footer {
      margin-bottom: 16px;
      height: 60px;
    }

    .short {
      color: $c-white;
      font-weight: 200;
      font-size: clamp(16px, 3vw, 20px);
      margin-bottom: 32px;
      line-height: clamp(24px, 3vw, 28px);
    }

    .contact--info {
      .item {
        display: flex;
        align-items: center;

        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          margin-right: 16px;
          background: transparent;
          border-radius: 8px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 36px;
          }
        }

        .value {
          display: flex;
          flex-direction: column;

          a {
            color: $c-white;
            font-size: clamp(14px, 3vw, 16px);
            margin-bottom: 4px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .social {
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 16px;

      a {
        height: 50px;
        width: 50px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $c-main;
        border-radius: 4px;
        transition: transform 1s ease-in-out;

        svg {
          height: 32px;
        }

        &:hover {
          background-color: $c-main;
          transition: transform 1s ease-in-out;

          i {
            transform: scale(1.2);
          }
        }
      }

      .facebook {
        background-color: #1877f2 !important;
      }
      .instagram {
        background-color: #e4405f !important;
      }
    }
  }

  .main {
    background-color: $c-main-light !important;

    p {
      text-align: center;
      color: $c-white;
      font-size: clamp(14px, 3vw, 16px);
    }
  }
}


.copyright {
  text-align: center;
  padding: 24px 0;

  p {
    text-align: center;
    font-size: clamp(14px, 3vw, 14px);
    color: $c-text;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;

    img {
      height: 16px;
      margin: 0 8px;
    }
  }

  small {
    color: $c-gray;
    
    a {
      color: rgb(255, 200, 0);
    }

    a:last-child {
      color: #f0b670;

    }
  }

 
}



