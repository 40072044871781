.grid--2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    grid-gap: 16px;

    @media (max-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    }
}

.grid--2-responsive {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    grid-gap: 16px;

    @media (max-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
    }

    @media (max-width: 767px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
}

.grid--3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
    grid-gap: 16px;

    @media (max-width: 991px) {
        grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
}

.grid--4 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }

  @media (max-width: 600px) {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

.flex--4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: 991px) {
      flex-wrap: wrap;
  }
}

.grid--5 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18%, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  }

  @media (max-width: 767px) {
      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
}


.grid--6 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  grid-gap: 16px;

  @media (max-width: 991px) {
      grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  }

  @media (max-width: 767px) {
      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  }
}
// 


// 
.instagram__area {
  background-image: url('/assets/images/banner--cma.jpg');
  background-size: cover;
  
  padding: 24px;
  border-radius: 24px;
  margin-bottom: 60px;
  border: 1px solid rgba($c-gray, .2);

  .head {
    text-align: center;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;

    @media (max-width: 500px) {
      flex-wrap: wrap;
    justify-content: center;
    }

    h3 {
      font-size: clamp(24px, 3vw, 32px);
      font-weight: 300;
      color: $c-main;
    }
    button {
      span {
        margin-left: 8px;
      }
    }

    
  }

  &--item {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: white;
    border: 1px solid rgba($c-gray, .3);
    border-radius: 4px;
    cursor: pointer;
    width: 16%;

    @media (max-width: 1199px) {
      width: 19%;
    }
    @media (max-width: 991px) {
      width: 24%;
    }
    @media (max-width: 767px) {
      width: 32%;
    }

  
    &--image {
        height: auto;
        object-fit: cover;
        width: 100%
    }

    &--title {
        --minFontSize: 14px;
        --maxFontSize: 14px;
        --scaler: 3vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        word-spacing: 4px;
        font-weight: 500;
        color: $c-dark;
        text-align: center;
        padding-top: 12px;
    }

    &:hover &--overlay {
        opacity: 1;
        width: 100%;
        height: 100%;
        
    }

    &--overlay  {
        background: rgba($c-second,.4);
        position: absolute;
        margin: auto;
        width: 0px;
        height: 0px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        -webkit-transition: .4s ease;
        transition: .4s ease;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: white;
            width: 50px;
        }
    }

}
}


// 

.image__shape {
  position: relative;
  text-align: left;
  border-radius: 16px;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 6%;
    background-color: $c-light;
    border: 1px solid $c-light;
    border-radius: 16px;
    height: 380px;
    width: 96%;
    z-index: -1;
    
  }

  transition: border ease-in .4s;

    &:hover {
      &::after {
      // border: 1px solid $c-main;
      // transition: border ease-in .4s;
      }
    }

  img {
    height: 380px;
    width: 96%;
    object-fit: cover;
    object-position: center;
    border-radius: 16px;
    z-index: 0;
    transition: transform ease-in .4s;

    &:hover {
      transform: scale(1.02);
      transition: transform ease-in .4s;
    }
  }
}

.image__shape--right {
  position: relative;
  text-align: right;
  border-radius: 16px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 6%;
    background-color: $c-light;
    border: 1px solid $c-light;
    border-radius: 16px;
    height: 380px;
    width: 96%;
    z-index: -1;
   
  }

  transition: border ease-in .4s;

  &:hover {
    &::after {
    // border: 1px solid $c-main;
    // transition: border ease-in .4s;
    }
  }
  img {
    height: 380px;
    width: 96%;
    object-fit: cover;
    object-position: center;
    border-radius: 16px;
    z-index: 0;
    transition: transform ease-in .4s;

    &:hover {
      transform: scale(1.02);
      transition: transform ease-in .4s;
    }
  }
}



