//Sizing Variables
$grid-breakpoints: (
  xs: 0,
  ss: 380px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

//Colours
$c-main: #E62555;
$c-main-dark: #cd1a47;
$c-main-light: #f23a68;
// $c-second: #061134;#282D5B
$c-second: #323646;
$c-text: #4D5686;
$c-gray: #CAD1DE;
$c-dark2: #878FB9;
$c-dark: #05091e;
$c-dark3: #030E2F;
$c-black: #000000;
$c-white: #ffffff;
$c-light: #F8F9FF;


$c-danger: #f2495a;
$c-success: #22d07c;

$c-main2: #ff5e79;
$c-second-light: rgb(247, 247, 247);
$c-orange: #FF9933;


$c-background: #F7F9FF;
$c-background2: #060F15;
$c-gray-e1: #E1E4F7;
$c-gray: #8498AF;
$c-gray-76: #425576;
$c-gray-d2: #CAD1DE;
$c-gray-light: #ECEEF2;
$c-gray-9c: #83839c;
$c-gray-l2: #F8F8FF;
$c-gray-ff: #E9F0EF;
$c-gray-f4: #E7EAF4;
$c-blue-dark: #002E74;
$c-dark-2: #0D285A;


$c-grey-33: #333333;
$c-grey-f7: #f7f7f7;
$c-grey-ab: #ababab;
$c-grey-b9: #b9b9b9;



