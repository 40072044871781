.partners__area {

  margin: 20px 0 80px 0;

  &--item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    background-color: $c-white;
    padding: 16px;
    border-radius: 16px;
    transition: border ease-in .3s;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid $c-main;
      transition: border ease-in .3s;
    }

    

    .media {
       
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
       
      }
    }

    .content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 8px;

      h3 {
        font-size: clamp(14px, 3vw, 18px);
        font-weight: 600;
        color: $c-main-dark;
        margin-bottom: 8px;
      }

      h4 {
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 600;
        color: $c-second;
        margin-bottom: 8px;
      }

      a {
        background-color: rgba($c-second, .04);
        padding: 4px 8px;
        border-radius: 4px;
        color: $c-text;
        font-size: clamp(14px, 3vw, 16px);
        font-weight: 600;
      }
    }
  }
}