
.breadcrumb__area {
    height: 260px;
    background-size: 100%;
    background-position: center;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    margin-bottom: 32px;
    margin-top: 16px;

    @media (max-width: 991px) {
        justify-content: center;
        // height: auto;
        padding-bottom: 0px;
        padding: 40px 0;
        text-align: center;
        border-radius: 16px;
    }
    
    &--content {
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        padding: 16px;

        @media (max-width: 700px) {
            padding: 8px;
        }


        .head {
            text-align: center;
            h1,h2 {
                font-size: clamp(32px, 3vw, 48px);
                font-weight: 700;
                color: $c-white;
                margin-bottom: 0;
            }
        }

       
        p {
            font-size: clamp(16px, 3vw, 20px);
            color: white;
            line-height: 32px;
            padding-top: 8px;
            margin-bottom: 0;
            @media (max-width: 991px) {
                padding-top: 8px;
            }
        }
    }

   
}
.breadcrumb--min {
    min-height: 80px !important;
    height: 100% !important;
}
