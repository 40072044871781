.search__area {
    background-color: $c-white;
    border-radius: 16px;
    padding: 24px;
    // padding-bottom: 40px;
    position: relative;
    margin-bottom: 40px;

    @media (max-width: 512px) {
      padding: 16px;
      padding-bottom: 40px;
    }

    &--head {
        margin-bottom: 24px;
    }

    .selector {
        position: relative;
        width: 100%;
        height: 50px;

        &--item {
            position: relative;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &__radio {
                appearance: none;
                display: none;
            }
            &__label {
                position: relative;
                width: 100%;
                text-align: center;
                height: 50px;
                display: flex;
                padding: 0 16px;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                transition-duration: 0.5s;
                transition-property: transform, color, box-shadow;
                transform: none;
                background-color: rgba($c-white, 1);
                border: 1px solid rgba($c-gray, 0.4);
                border-radius: 8px;
                color: $c-gray;
                background-color: rgba($c-second, .05);
                border: 1px solid rgba($c-second, .1);
                color: $c-second;
                cursor: pointer;
                font-size: clamp(12px, 3vw, 16px);
                
            }
        }
    }
    .selector--item__label:hover {
        background-color: rgba($c-main-light, 1);
        border: 1px solid $c-main-light;
        color: $c-white;
    }
    .selector--item__radio:checked + .selector--item__label {
        background-color: rgba($c-main-light, 1);
        border: 1px solid $c-main-light;
        color: $c-white;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        grid-gap: 16px;

        @media (max-width: 700px) {
          grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
        }
        @media (max-width: 512px) {
          grid-template-columns: repeat(auto-fit, minmax(28%, 1fr));
          grid-gap: 4px;

        }
    }

    .flex-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        width: auto;
    }

    &--inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;

        @media (max-width: 991px) {
          justify-content: center;
        }

      
        select,
        input {
          flex-grow: 1;
          height: 50px;
          border-radius: 4px;
          border: 1px solid rgba($c-gray, 0.4);
          background-color: rgba($c-white, 1);
          padding: 0 8px;
          min-width: 170px;
        }

        select::-ms-expand {
          display:none;
        } 

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
          min-width: 100%;
        }
    }

    button {
      // position: absolute;
      // bottom: -24px;
      // left: 0;
      // right: 0;
      margin: 0 auto;
      margin-top: 24px;
    }
}


.fronty__select {
  position: relative;
  flex-grow: 1;
  span {
    position: absolute;
    width: 50px;
    height: 100%;
    right: 0;
    top: 0;
    pointer-events: none;
  }
  
  
  span::after {
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 7px;
    border-color: rgba($c-gray, 0.8) transparent transparent transparent;
    top: 40%;
    right: 16px;
  }
}
