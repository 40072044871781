.propriety__type__area {
    margin: 120px 0 80px;

    &--item {
        position: relative;
        border-radius: 16px;
        overflow: hidden;

        .overlay {
            background-image: linear-gradient(
                to top,
                rgba($c-main, 0.8),
                rgba($c-second, 0.01)
            );
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 16px;
        }

        img {
            height: 180px;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 16px;
            transition: all ease-in 0.3s;
        }

        .content {
            position: absolute;
            bottom: 16px;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h4 {
                color: $c-white;
                margin-bottom: 4px;
                font-size: clamp(16px, 3vw, 18px);
                font-weight: 600;
                transition: all ease-in 0.3s;
            }

            span {
                color: $c-gray-light;
                font-size: clamp(12px, 3vw, 14px);
                font-weight: 400;
            }
        }

        &:hover {
            .content {
                h4 {
                    transform: scale(1.1);
                    transition: all ease-in 0.3s;
                }
            }
            img {
                transform: scale(1.2);
                transition: all ease-in 0.3s;
            }
        }
    }
}

.category__type__area {
    .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        padding: 8px;
        border-radius: 8px;
        background-color: $c-white;
        margin-bottom: 8px;
        border: 1px solid transparent;
        transition: border ease-in 0.3s;

        &:hover {
            border: 1px solid rgba($c-main, 0.5);
            transition: border ease-in 0.3s;
        }

        &:last-child {
            margin-bottom: 0;
        }

        img {
            height: 40px;
            width: 40px;
            border-radius: 4px;
            object-fit: cover;
        }

        .content {
            h4 {
                font-size: clamp(14px, 3vw, 16px);
                font-weight: 600;
                color: $c-text;
                margin-bottom: 4px;
            }
            span {
                color: $c-gray;
                font-size: clamp(12px, 3vw, 14px);
            }
        }
    }
}

.bg--dark {
    background-color: $c-dark;
    border: 4px dotted rgba($c-white, 0);
    border-radius: 24px;
    padding: 24px;
    &:hover {
        border: 4px solid rgba($c-white, 0.8);
    }

    @media (max-width: 991px) {
        padding: 16px;
    }
}

.bg--main {
    background-color: $c-main-light;
    border: 4px dotted rgba($c-white, 0);
    border-radius: 24px;
    padding: 24px;
    &:hover {
        border: 4px solid rgba($c-white, 0.8);
    }

    @media (max-width: 991px) {
        padding: 16px;
    }
}

.propriety__area {
    &--item {
        background-color: $c-white;
        border-radius: 16px;
        overflow: hidden;

        .media {
            position: relative;
            overflow: hidden;
            img {
                height: 300px;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                transition: all ease-in 0.3s;
            }

            .tags {
                position: absolute;
                top: 16px;
                right: 16px;
                display: flex;
                align-items: center;
                gap: 8px;

                .category {
                    background-color: $c-main;
                    border-radius: 24px;
                    padding: 4px 8px;
                    color: rgba($c-white, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                }
                .visitor {
                    background-color: $c-dark2;
                    border-radius: 24px;
                    padding: 4px 8px;
                    color: rgba($c-white, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }
        }

        .content {
            padding: 16px;

            .line {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .location {
                    color: rgba($c-text, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                .time {
                    background-color: $c-gray-light;
                    border-radius: 24px;
                    padding: 4px 8px;
                    color: rgba($c-text, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }

            a {
                text-decoration: none;
                color: $c-second;

                h2 {
                    margin-bottom: 0;
                    padding: 16px 0;
                    font-size: clamp(16px, 3vw, 20px);
                    font-weight: 700;
                }
            }

            .features {
                padding-bottom: 16px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .item {
                    min-width: 180px;
                }
            }

            .price {
                border-top: 1px solid $c-gray-light;
                padding-top: 16px;
                text-align: center;

                h3 {
                    margin-bottom: 0;
                    font-size: clamp(16px, 3vw, 24px);
                    color: $c-main;
                    font-weight: 700;
                }
            }
        }

        &:hover {
            .content {
                a {
                    &:hover {
                        color: $c-text;
                    }
                }
            }
            .media {
                img {
                    transform: scale(1.04);
                    transition: all ease-in 0.3s;
                }
            }
        }
    }

    &--horizontal {
        background-color: $c-white;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 16px;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .media {
            position: relative;
            overflow: hidden;
            width: 35%;
            @media (max-width: 767px) {
                width: 100%;
            }

            img {
                height: 300px;
                width: 100%;
                object-fit: fill;
                object-position: center;
                border-top-left-radius: 16px;
                border-bottom-left-radius: 16px;
                transition: all ease-in 0.3s;

                @media (max-width: 767px) {
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                }
            }
        }

        .content {
            padding: 16px;
            width: 65%;
            @media (max-width: 767px) {
                width: 100%;
            }
            .tags {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;

                .flex-row {
                    gap: 8px;
                    display: flex;
                    align-items: center;
                }

                .category {
                    background-color: $c-main;
                    border-radius: 24px;
                    padding: 4px 8px;
                    color: rgba($c-white, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                }
                .visitor {
                    background-color: $c-dark2;
                    border-radius: 24px;
                    padding: 4px 8px;
                    color: rgba($c-white, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
                .location {
                    color: rgba($c-text, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }

                .time {
                    background-color: $c-gray-light;
                    border-radius: 24px;
                    padding: 4px 8px;
                    color: rgba($c-text, 1);
                    font-weight: 600;
                    font-size: clamp(12px, 3vw, 16px);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                }
            }

            a {
                text-decoration: none;
                color: $c-second;

                h2 {
                    margin-bottom: 0;
                    padding: 16px 0;
                    font-size: clamp(16px, 3vw, 20px);
                    font-weight: 700;
                }
            }

            .features {
                padding-bottom: 16px;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .line {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .item {
                    min-width: 180px;
                }
            }

            .price {
                padding-top: 16px;
                h3 {
                    margin-bottom: 0;
                    font-size: clamp(16px, 3vw, 24px);
                    color: $c-main;
                    font-weight: 700;
                }
            }

            .actions {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 16px;

                border-top: 1px solid $c-gray-light;
            }
        }

        &:hover {
            .content {
                a {
                    &:hover {
                        color: $c-text;
                    }
                }
            }
            .media {
                img {
                    // transform: scale(1.2);
                    // transition: all ease-in .3s;
                }
            }
        }
    }


    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;

        .page-link {
            height: 48px;
            width: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: rgba($c-second, 1);
        }

        .active {
            span {
                background-color: $c-main;
                border-color: $c-main;
                color: $c-white;
            }
        }

      
    }
}

.sort__area {
    padding-bottom: 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    p {
        margin-bottom: 0;
        color: $c-dark;

        strong {
            color: $c-second;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        .select {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $c-white;
            border-radius: 8px;
            padding-left: 16px;
            padding-right: 8px;
            height: 50px;
            label {
                display: flex;
                align-items: center;
                margin-right: 8px;

                svg {
                    margin-right: 8px;
                }
            }

            select {
                height: 100%;
                width: 100%;
                border: none;
                padding: 16px 0 16px 16px;
                border-radius: 8px;
                color: $c-dark;
            }
        }
    }
}

.single__propriety {
    &--image {
        .swiper-slide {
            background-color: $c-white;
            border-radius: 8px;
            border: 1px solid rgba($c-gray, 0.2);
            width: 100%;
            height: 420px;
            img {
                height: 100%;
                object-fit: cover;
                object-position: center;
                width: 100%;
                aspect-ratio: auto 1 / 1;
                border-radius: 8px;
            }
            @media (max-width: 767px) {
                height: 360px;
            }
        }
    }
    &--thumbs {
        margin-top: 16px;
        img {
            height: 80px;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 8px;
            border: 1px solid rgba($c-gray, 0.2);
            @media (max-width: 767px) {
                height: 80px;
            }
        }
    }

    &--content {
        margin: 40px 0;
        background-color: rgba($c-white, 1);
        border-radius: 16px;
        padding: 16px;

        .head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            margin-bottom: 16px;

            h3 {
                font-size: clamp(16px, 3vw, 20px);
                font-weight: 600;
                margin-bottom: 0;
                color: rgba($c-second, 1);

                small {
                    color: rgba($c-gray, 1);
                    font-weight: 400;
                }
            }
            span {
                font-size: clamp(16px, 3vw, 24px);
                font-weight: 700;
                color: rgba($c-main, 1);
            }
        }

        .tags {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            margin-bottom: 16px;
            flex-wrap: wrap;
            .right {
                gap: 8px;
                display: flex;
                align-items: center;
            }

            .category {
                background-color: $c-main;
                border-radius: 24px;
                padding: 4px 8px;
                color: rgba($c-white, 1);
                font-weight: 600;
                font-size: clamp(12px, 3vw, 16px);
            }
            .visitor {
                background-color: $c-dark2;
                border-radius: 24px;
                padding: 4px 8px;
                color: rgba($c-white, 1);
                font-weight: 600;
                font-size: clamp(12px, 3vw, 16px);
                display: flex;
                align-items: center;
                gap: 4px;
            }
            .location {
                color: rgba($c-text, 1);
                font-weight: 600;
                font-size: clamp(12px, 3vw, 16px);
                display: flex;
                align-items: center;
                gap: 4px;
            }

            .time {
                background-color: $c-gray-light;
                border-radius: 24px;
                padding: 4px 8px;
                color: rgba($c-text, 1);
                font-weight: 600;
                font-size: clamp(12px, 3vw, 16px);
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }

        .body {
          margin: 24px 0;

          .t--paragraph--1 > * {
            color: $c-second !important;
          }
          
        }

        .video {
          margin: 24px 0;
        }

        .features {
          margin: 24px 0;
        }


        .list--wrap {
          margin-top: 16px;
          .group {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 16px;
          
            @media (max-width: 1199px) {
              grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
            }

            @media (max-width: 767px) {
              grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            }
            margin-bottom: 8px;
          }
          &--item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            background-color: $c-background;
            border-radius: 8px;
            padding: 8px 16px;
          }
        }
    }

    &--card {
      background-color: rgba($c-white, 1);
      border-radius: 16px;
      padding: 16px;

      .agent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 16px;
        margin-bottom: 16px;
        img {
          width: 90px;;
          height: 90px;
          border-radius: 8px;
          object-fit: cover;
          object-position: top;
        }
      }

      .contact {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        gap: 16px;
      }

      .share {
        margin-top: 24px;
      }
    }


    .head--h4 {
      font-size: clamp(16px, 3vw, 20px);
      font-weight: 600;
      margin-bottom: 8px;
    }
    .head--h5 {
      font-size: clamp(16px, 3vw, 18px);
      font-weight: 600;
      margin-bottom: 8px;
      color: $c-main;
    }
}
